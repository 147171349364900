<template>
  <v-container class="my-6">
    <v-card>
      <v-card-title>
        <div
          v-if="$store.getters['usuario/loggedIn']"
        >
          Mi Carrito
        </div>

        <div
          v-if="!$store.getters['usuario/loggedIn']"
        >
          Por favor <strong class="txt-primary">Regístrate</strong> para continuar con tu compra.
        </div>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="cart"
        disable-pagination
        :hide-default-footer="true"
      >
        <template v-slot:[`item.producto.precio`]="{ item }">
          $ {{ item.producto.precio }}
        </template>
        <template v-slot:[`item.subtotal`]="{ item }">
          $ {{ item.producto.precio * item.qty }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            color="primary"
            class="mx-2"
            @click="removeProductFromCart(item)"
            small
          >
            <v-icon>
              mdi-minus
            </v-icon>
          </v-btn>
          <v-btn
            color="secondary"
            class="mx-2"
            @click="addProduct(item)"
            small
            dark
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn> 
        </template>
        <template v-slot:[`body.append`]="{ headers }">
          <tr>
            <td :colspan="headers.length">
              <!-- poner totalCost!! -->
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-card-actions class="py-4 text-left">
        <!-- <div> -->
        <!--   {{ this.totalCost }} -->
        <!-- </div> -->
        <v-btn v-if="$store.getters['usuario/loggedIn']"
          :disabled="!cart.length"
          color="primary"
          exact to="/checkout"
        >
          Continuar con la compra
        </v-btn>
        <login-card
          v-else
          buttonText="Continuar con la compra"
          :showIcon="false"
        />
      </v-card-actions>
    </v-card>

  </v-container>
</template>

<script>
  import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
  import LoginCard from '../../components/utils/accountDialogs/LoginCard.vue'
  export default {
    metaInfo: {
      title: 'Carrito'
    },
  components: { LoginCard },
    data() {
      return {
        headers: [
          { text: 'Producto', value: 'producto.nombre_producto', sortable: false},
          { text: 'Precio', value: 'producto.precio'},
          { text: 'Cantidad', value: 'qty'},
          { text: 'Subtotal', value: 'subtotal'},
          { text: 'Actions', value: 'actions', sortable: false},
        ]
      }
    },
    computed: {
      ...mapState('cart', ['cart']),
      ...mapGetters('cart', ['totalCost'])
    },
    methods: {
      ...mapMutations('cart', ['removeProductFromCart', 'addProduct']),
      ...mapActions('cart', ['distanciaSucursalCliente']),
      ...mapMutations('usuario', ['updateSelectedAddress']),
    },
    created() {
      if (this.$store.getters['usuario/loggedIn'] && this.cart.length) {
        if (JSON.parse(localStorage.getItem('userAddress'))) {
          let defaultAddress = JSON.parse(localStorage.getItem('userAddress'))
          this.updateSelectedAddress(defaultAddress)
          
          this.distanciaSucursalCliente()
        }
      }
    }
  }
</script>

<style>
.v-data-table-header tr th {
  background-color: #FF3700;
}
.v-data-table-header tr th span {
  color: white;
}
</style>
